
@keyframes show-navbar-dropdown {
    0% {
        opacity: 0;
        transition: visibility 0.25s, opacity 0.25s, transform 0.25s;
    }

    100% {
        opacity: 1;
    }
}
