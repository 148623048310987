/* Mandated by Google branding guidelines for OAuth support */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

/* Reference font for main taglines */
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;500;700&display=swap');

/* Import Bootstrap 5 default variables */
@import 'assets/scss/argon.scss';

/* You can add global styles to this file, and also import other style files */
.jumbotron-bg-home {
    background-image: url(./assets/img/home-bg.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.4;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
}

#jumbotron-home-carousel {
    .carousel-control-prev, .carousel-control-next {
        width: 1rem;
    }

    .carousel-indicators {
        bottom: 0px;
    }

    .carousel-item {
        display: block !important;
        opacity: 0;
        z-index: 0;
        transition: all 1.5s ease-in-out 0s !important;
    }

    .carousel-item.active {
        opacity: 1;
        z-index: 1;
        transition: all 1.5s ease-in-out 0.5s !important;
    }
}

/* You can add global styles to this file, and also import other style files */
.jumbotron-bg {
    background-image: url(./assets/img/bg.svg);
    background-size: cover;
    // background-position: center;
    background-position-x: 50px;
    background-position-y: -20px;
    background-repeat: no-repeat;
    opacity: 0.3;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
}

.spinner-large {
    width: 5rem;
    height: 5rem;
}

.btn-google {
    background-color: white;
    border: 2px solid #444444;
}

.btn-facebook {
    background-color: #1877F2;
    border: 2px solid #0b5ac1;
    color: white;
}

.btn-multiline {
    white-space: normal !important;
}

.rounded-1 {
    border-radius: .25rem !important;
}

.rounded-2 {
    border-radius: .5rem !important;
}

.food-icon {
    font-size: 1.5rem;
}

.text-black {
    color: #000000;
}

/* FIXME: quickfix*/
.noUi-origin {
    width: 10% !important;
}

.noUi-tooltip {
    font-size: 0.7rem;
}

.cursor-pointer {
    cursor: pointer;
}


.cursor-not-allowed {
    pointer-events: visible !important;
    cursor: not-allowed !important;
}

// support for not-allowed cursor on buttons
// https://github.com/twbs/bootstrap/issues/16088
.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
    cursor: not-allowed;
}

.bg-dashed {
    background-image: repeating-linear-gradient( 45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1) 10px, rgba(0, 0, 0, 0.2) 10px, rgba(0, 0, 0, 0.2) 20px );
}

// support smooth anchor scrolling (via fragment in URL)
// https://stackoverflow.com/a/55943175
html {
    scroll-behavior: smooth;
}

.rd-icon {
    padding: 0.7rem;
}

.rd-icon > img {
    height: 2rem;
}

// Override style from intlTelInput to make validation
// styles take the full width of the phone number field
.iti {
    display: block !important;
}

// Fix: remove overlay displayed above ngbModal
// https://github.com/ng-bootstrap/ng-bootstrap/issues/2686#issuecomment-1139955512
ngb-modal-backdrop {
  z-index: 1050 !important;
}

// prevent scrollbar from disappearing when opening a modal view.
// See https://stackoverflow.com/a/40617486
.modal-open {
    overflow: inherit !important;
    padding-right: 0px !important;  // not in the stackoverflow answer but get's added, probably due to Argon framework.
}

// remove dropdown downward triangle (default in Bootstrap 4)
.dropdown-toggle.no-dropdown-icon::after {
    content: none !important;
}

// display an element using the full page width (ignore container)
// See https://stackoverflow.com/a/37729410
.vw-100 {
    width: 100vw;
    position: relative;
    margin-left: -50vw;
    left: 50%;
}
